document.addEventListener('DOMContentLoaded', function() {
    
    let backgroundPlayer;

    // Initialize the background player
    function initializeBackgroundPlayer(backgroundIframe) {
        if (backgroundIframe) {
            backgroundPlayer = new Vimeo.Player(backgroundIframe);
        }
    }

    // Open the modal
    document.querySelectorAll('[data-toggle="modal-video"]').forEach(function(button) {
        button.addEventListener('click', function() {

            const videoSource = button.getAttribute('data-video-source');
            const videoTarget = button.getAttribute('data-video-target');
            const modal = document.getElementById('modalVideo');
            const modalContent = modal.querySelector('.modal-video--content');

            // Clear previous content
            modalContent.innerHTML = '';

            // Get the video element by ID
            const videoElement = document.getElementById(videoTarget);

            if (videoElement) {
                if (videoSource === 'vimeo') {
                    initializeBackgroundPlayer(videoTarget);

                    // For Vimeo, clone the player <iframe>
                    const iframeClone = videoElement.cloneNode(true);

                    // Modify the src attribute to unmute and disable looping
                    let src = iframeClone.getAttribute('src');

                    // Create a URL object to manipulate the parameters
                    const url = new URL(src, window.location.origin);

                    // Set desired parameters, remove any unnecessary
                    url.searchParams.delete('background');

                    url.searchParams.set('autoplay', '0');
                    url.searchParams.set('loop', '0');
                    url.searchParams.set('muted', '0');
                    url.searchParams.set('controls', '1');
                    url.searchParams.set('byline', '0');
                    url.searchParams.set('title', '0');
                    url.searchParams.set('portrait', '0');

                    // Set the updated src on the cloned <iframe>
                    iframeClone.setAttribute('src', url.href);

                    iframeClone.style.width = '100%';
                    iframeClone.style.height = '100%';

                    // Append cloned <iframe> to modal content
                    modalContent.appendChild(iframeClone);

                    // Create a Vimeo Player instance for the modal video; otherwise interacting with modal inadvertently affects the background content video
                    const modalPlayer = new Vimeo.Player(iframeClone);

                    // Pause the background video
                    if (backgroundPlayer) {
                        backgroundPlayer.pause().catch(function(error) {
                            console.error('Error pausing background video:', error);
                        });
                    }

                    // Play the modal video
                    modalPlayer.play().catch(function(error) {
                        console.error('Error playing modal video:', error);
                    });

                } else {
                    // For Shopify-hosted video, create a new, empty <video> element
                    // "Why not just clone the <video>?" Browser behaviour is inconsistent and the action regularly fails, sometimes without clear error reporting.
                    // To avoid this, we instead create an new <video> element and append the cloned <source> elements
                    const videoClone = document.createElement('video');

                    // Copy the <source> elements
                    const sources = videoElement.querySelectorAll('source');
                    sources.forEach(function(source) {
                        const sourceClone = source.cloneNode(true);
                        videoClone.appendChild(sourceClone);
                    });

                    // Set cloned <video> attributes
                    videoClone.setAttribute('controls', true);
                    videoClone.setAttribute('loop', false);
                    videoClone.setAttribute('muted', false);
                    videoClone.setAttribute('autoplay', false);  // Explicitly set autoplay to false
                    videoClone.style.width = '100%';

                    // Append to modal content
                    modalContent.appendChild(videoClone);

                    // Ensure the video is paused and reset to the beginning
                    videoClone.pause();
                    videoClone.currentTime = 0;
                }
            } else {
                console.error('Video element not found with ID:', videoTarget);
            }

            // We have our video, now display the modal
            modal.style.display = 'block';
        });
    });

    // Close the modal
    function closeModal() {
        const modal = document.getElementById('modalVideo');
        modal.style.display = 'none';

        // Remove the <video> or <iframe> element
        modal.querySelector('.modal-video--content').innerHTML = '';

        // Resume the background video
        if (backgroundPlayer) {
            backgroundPlayer.play().catch(function(error) {
                console.error('Error resuming background video:', error);
            });
        }
    }

    document.querySelectorAll('.modal-video--close').forEach(function(closeButton) {
        closeButton.addEventListener('click', closeModal);
    });

    // Close modal when clicking on backdrop/outside of the content
    window.addEventListener('click', function(event) {
        const modal = document.getElementById('modalVideo');
        if (event.target === modal) {
            closeModal();
        }
    });
});
